<template>
  <div class="drPickWrap">
    <!-- popup size 929 * 628 (상단 :: 32, contents :: 596 ) gathering-div3 -->
    <div class="gathering-wrap gathering-div3">
      <div class="title-bar">
        <h1>모아보기 약품정보+설문조사+정보</h1>
      </div>
      <div
        v-if="isDrugOneSide"
        class="drug-info-items item-type"
      >
        <h2>약품 정보</h2>
        <div class="info-wrap">
          <button
            v-if="!isSurveyOneSide"
            class="btn-info"
            @click.stop="isDrugInfotip = !isDrugInfotip"
          >
            <i />
          </button>
          <popup-info-tip
            :show-info-tip="isDrugInfotip"
            @close="isDrugInfotip = false"
          />
        </div>
        <div class="gathering-list">
          <ul>
            <li
              v-for="item in drugInfos"
              :key="item.liveInfoId"
            >
              <a
                @click.prevent="linkDrugInfo(item.contentType, item.liveInfoId)"
              >
                <p>{{ item.title }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        v-if="isSurveyOneSide"
        class="survey-items item-type"
      >
        <h2 v-if="drugLandings.length || !surveys.length">
          간편 처방등록
        </h2>
        <div class="info-wrap">
          <button
            class="btn-info"
            @click.stop="isLandingInfoTip = !isLandingInfoTip"
          >
            <i />
          </button>
          <popup-info-tip
            :show-info-tip="isLandingInfoTip"
            @close="isLandingInfoTip = false"
          />
        </div>
        <div
          v-if="drugLandings.length"
          class="gathering-list"
        >
          <ul>
            <li
              v-for="item in drugLandings"
              :key="item.liveInfoId"
            >
              <a
                @click.prevent="callDrugLanding(item.liveInfoId)"
              >
                <p>{{ item.title }}</p>
              </a>
            </li>
          </ul>
        </div>
        <h2 v-if="surveys.length">
          설문 조사
        </h2>
        <div class="info-wrap">
          <button
            class="btn-info"
            @click.stop="isSurveyInfotip = !isSurveyInfotip"
          >
            <i />
          </button>
          <popup-info-tip
            :show-info-tip="isSurveyInfotip"
            @close="isSurveyInfotip = false"
          />
        </div>
        <div
          v-if="surveys.length"
          class="gathering-list"
        >
          <ul>
            <li
              v-for="item in surveys"
              :key="item.liveInfoId"
            >
              <a
                @click.prevent="linkSurvey(item.contentType, item.liveInfoId)"
              >
                <p><span>[{{ item.survey.summary }}]</span>{{ item.title }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="gathering-else">
        <swiper
          v-if="topBanner.length > 0"
          ref="topBanner"
          class="swiper trendBanner"
          :options="swiperOption"
          @click.native="clickTopBanner"
        >
          <swiper-slide
            v-for="item in topBanner"
            :key="item.id"
          >
            <a>
              <img
                :src="item.filePath"
                width="242"
                height="300"
                loading="lazy"
              >
            </a>
          </swiper-slide>
        </swiper>
        <swiper
          v-if="bottomBanner.length > 0"
          ref="bottomBanner"
          class="swiper noticeBanner"
          :options="swiperOption"
          @click.native="clickBottomBanner"
        >
          <swiper-slide
            v-for="item in bottomBanner"
            :key="item.id"
          >
            <a>
              <img
                :src="item.filePath"
                width="242"
                height="250"
                loading="lazy"
              >
            </a>
          </swiper-slide>
        </swiper>
        <button
          id="btnGo"
          class="btn-go"
          @click.prevent="callDrInfo(null)"
        >
          닥터인포 바로가기
        </button>
      </div>
    </div>

    <alert-dialog :options="alertProps" />
  </div>
</template>

<script>
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import eventBus from '@/libs/event/event-bus'
import { liveInfoEventHandler, callExternalApi } from '@/core/global/global-liveinfo'
import { isUserLoggedIn, getUserData } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'
import PopupInfoTip from './popup/PopupInfoTip.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    PopupInfoTip,
  },
  data() {
    return {
      subject: null,
      shaYoyangNum: null,
      shaEnLicenseNum: null,
      drugInfos: [],
      surveys: [],
      drugLandings: [],
      seminars: [],
      isDrugOneSide: true,
      isSurveyOneSide: true,
      liveWebSeminarCount: 0,
      liveMyWebSeminarCount: 0,
      isLogin: false,
      swiperOption: {
        slidesPerView: 1,
        centeredSlides: false,
        slideToClickedSlide: false,
        // grabCursor: false,
        initialSlide: 0,
        paginationClickable: false,
        scrollbar: ".swiper-scrollbar",
        // touchRatio: 0,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      },
      topBanner: [],
      bottomBanner: [],
      loginTimer: null,
      isDrugInfotip: false,
      isSurveyInfotip: false,
      isLandingInfoTip: false,
    }
  },
  computed: {
  },
  // watch: {
  //   isLogin(val) {
  //     if (val === true) this.fetchMySeminar()
  //   },
  // },
  created() {
    // 배너 불러오기
    this.getBanners()
  },
  mounted() {
    // 로그인 체크 타이머
    this.loginTimer = this.loginTimerStart()

    eventBus.$on(liveInfoEventHandler, async payload => {
      switch (payload.funcName) {
        case 'getMoaInitData':
          this.getMoaInitData(payload.p)
          break

        case 'InjectRefreshToken':
          if (payload.p.refreshToken) {
            useJwt.setRefreshToken(payload.p.refreshToken)
            await this.refreshTokenLogin()
          }
          break

        case 'CallLandingPopupError':
          if (payload.p.resultCode) {
            const errorNumber = Number(payload.p.resultCode)
            if (errorNumber === -1) this.showAlertDialog('진료실을 호출할 수 없습니다.\n관리자에게 문의해주세요.')
            else if (errorNumber === 5) this.showAlertDialog('진료실 호출 권한이 없습니다.\n관리자에게 문의해주세요.')
            else this.showAlertDialog(`간편 처방등록 팝업 호출에 실패하였습니다. [${errorNumber}]\n관리자에게 문의해주세요.`)
          }
          break

        case 'LandingComplete':
          if (payload.p.liveInfoId) {
            const liveInfoId = Number(payload.p.liveInfoId)
            this.drugLandings = this.drugLandings.filter(x => x.liveInfoId !== liveInfoId) // 완료된 liveInfoId 제외
          }
          break

          // case 'updateSeminarCount':
          //   this.fetchMySeminar()
          //   break

        default:
          break
      }
    })
  },
  methods: {
    loginTimerStart() {
      const interval = setInterval(() => {
        this.checkLogin()
      }, 5000)

      return interval
    },
    loginTimerStop() {
      clearInterval(this.loginTimer)
    },
    async refreshTokenLogin() {
      // isUserLoggedIn에서 자동 로그인 수행
      this.isLogin = await isUserLoggedIn()

      if (this.isLogin) {
        this.loginTimerStop()
      }
    },
    checkLogin() {
      const userData = getUserData()

      if (userData && userData.userName) {
        // 로그인되면 해제 (*로그아웃까지는 체크하지 않음)
        this.isLogin = true
        this.loginTimerStop()
      }
    },
    // (240513) 모아보기 리뉴얼로 인한 참여가능한 웹 세미나 수 등 제외
    // fetchMySeminar() {
    //   axios.get('/fu/moa/myseminarcount')
    //     .then(rs => {
    //       this.liveMyWebSeminarCount = rs.data.myseminarCnt
    //       this.liveWebSeminarCount = rs.data.liveSeminarCnt
    //     })
    //     .catch(() => {
    //     })
    // },
    linkContent(linkType, id) {
      this.callDrInfo({ linkType, linkId: id })
    },
    callDrugLanding(id) {
      callExternalApi("CallLandingPopup", String(id))
    },
    linkSurvey(linkType, id) {
      axios.post(`/fu/survey/status/${id}`, {
        medicalDept: this.subject,
        shaYoyangNum: this.shaYoyangNum,
        shaEnLicenseNum: this.shaEnLicenseNum,
      })
        .then(rs => {
          const { isJoinAble, alertMessage } = rs.data
          if (isJoinAble) {
            this.linkContent(linkType, id)
            return
          }
          if (alertMessage) {
            this.showAlertDialog(alertMessage)
          }
        })
    },
    linkLogin() {
      this.linkContent(0, null)
    },
    callDrInfo(params) {
      callExternalApi("CallDrInfo", params ? JSON.stringify(params) : null)
    },
    getMoaInitData(data) {
      this.subject = data.subject
      this.shaYoyangNum = data.shaYoyangNum
      this.shaEnLicenseNum = data.shaEnLicenseNum
      this.drugInfos = data.drugInfos || []
      this.surveys = data.surveys || []
      this.drugLandings = data.drugLandings || []

      // this.fetchMySeminar()

      if (data.isDrugOneSide) { // 약품정보만 로드시 IsSurveyOneSide 는 false
        this.isSurveyOneSide = false
      }

      if (data.isSurveyOneSide) {
        this.isDrugOneSide = false
      }

      this.$store.commit('infoData/setMedicalDept', data.subject)
      this.$store.commit('infoData/setShaYoyangNum', data.shaYoyangNum)
      this.$store.commit('infoData/setShaEnLicenseNum', data.shaEnLicenseNum)
      if (data.region) this.$store.commit('infoData/setRegion', data.region)
    },
    getBanners() {
      axios.post("/fu/moa/banner", {
      })
        .then(rs => {
          if (rs.data && rs.data.length) {
            this.topBanner = rs.data.filter(x => x.bannerLocation === 1) // 상단배너
            this.bottomBanner = rs.data.filter(x => x.bannerLocation === 2) // 하단배너
          }
        })
        .catch(error => {
          this.showAlertDialog(error.response.data)
        })
    },
    clickTopBanner() {
      const item = this.topBanner[this.$refs.topBanner.$swiper.realIndex]

      this.linkContent(item.contentType, item.contentId)
    },
    clickBottomBanner() {
      const item = this.bottomBanner[this.$refs.bottomBanner.$swiper.realIndex]

      if (item.contentType === 1) {
        // 약품정보 배너 별도 처리
        this.linkDrugInfo(50, item.contentId) // 약품정보 배너 클릭에 대한 링크 유형은 '50'으로 호출
      } else {
        this.linkContent(item.contentType, item.contentId)
      }
    },
    linkDrugInfo(linkType, id) {
      axios.get(`/fu/druginfo/moa-info/${id}`)
        .then(async rs => {
          const { drugInfoType, externalLink } = rs.data

          if (drugInfoType === 1) {
            this.linkContent(linkType, id)
          } else if (externalLink !== null && externalLink.length > 0) {
            if (linkType === 1) setStatistics(130, id)
            else if (linkType === 50) setStatistics(170, id)

            callExternalApi("LinkUrl", externalLink)
          } else {
            this.linkContent(linkType, id)
          }
        })
    },
  },
}
</script>
