<template>
  <div
    v-if="showInfoTip"
    class="hover-info-box"
  >
    <button
      class="btn-info-close"
      @click.stop="close"
    >
      <i />
    </button>
    <p>유비케어는 마케팅 및 광고 활용, 신규 서비스 개발 및 특화, 이벤트 정보 전달 등 인구통계학적 특성에 따른 서비스 제공을 목적으로 노출, 접속, 참여, 알림, 클릭 등 서비스 이용 통계를 수집하며 이를 바탕으로 고객 맞춤형 광고를 제공합니다.</p>
    <p>*의사랑 진료실 설정 변경을 통해 서비스 이용 통계 수집 및 맞춤형 광고를 차단할 수 있습니다.</p>
    <p>*서비스 통계 수집 및 광고 차단 시 의사랑을 통해 제공되는 당사의 병의원 서비스 및 콘텐츠 이용이 제한될 수 있습니다. </p>
  </div>
</template>

<script>
export default {
  props: {
    showInfoTip: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    close() {
      this.$emit('close')
    }
  },
}
</script>
