var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drPickWrap" },
    [
      _c("div", { staticClass: "gathering-wrap gathering-div3" }, [
        _vm._m(0),
        _vm.isDrugOneSide
          ? _c("div", { staticClass: "drug-info-items item-type" }, [
              _c("h2", [_vm._v("약품 정보")]),
              _c(
                "div",
                { staticClass: "info-wrap" },
                [
                  !_vm.isSurveyOneSide
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-info",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.isDrugInfotip = !_vm.isDrugInfotip
                            },
                          },
                        },
                        [_c("i")]
                      )
                    : _vm._e(),
                  _c("popup-info-tip", {
                    attrs: { "show-info-tip": _vm.isDrugInfotip },
                    on: {
                      close: function ($event) {
                        _vm.isDrugInfotip = false
                      },
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "gathering-list" }, [
                _c(
                  "ul",
                  _vm._l(_vm.drugInfos, function (item) {
                    return _c("li", { key: item.liveInfoId }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.linkDrugInfo(
                                item.contentType,
                                item.liveInfoId
                              )
                            },
                          },
                        },
                        [_c("p", [_vm._v(_vm._s(item.title))])]
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e(),
        _vm.isSurveyOneSide
          ? _c("div", { staticClass: "survey-items item-type" }, [
              _vm.drugLandings.length || !_vm.surveys.length
                ? _c("h2", [_vm._v(" 간편 처방등록 ")])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "info-wrap" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-info",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.isLandingInfoTip = !_vm.isLandingInfoTip
                        },
                      },
                    },
                    [_c("i")]
                  ),
                  _c("popup-info-tip", {
                    attrs: { "show-info-tip": _vm.isLandingInfoTip },
                    on: {
                      close: function ($event) {
                        _vm.isLandingInfoTip = false
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.drugLandings.length
                ? _c("div", { staticClass: "gathering-list" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.drugLandings, function (item) {
                        return _c("li", { key: item.liveInfoId }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.callDrugLanding(item.liveInfoId)
                                },
                              },
                            },
                            [_c("p", [_vm._v(_vm._s(item.title))])]
                          ),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm.surveys.length ? _c("h2", [_vm._v(" 설문 조사 ")]) : _vm._e(),
              _c(
                "div",
                { staticClass: "info-wrap" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-info",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.isSurveyInfotip = !_vm.isSurveyInfotip
                        },
                      },
                    },
                    [_c("i")]
                  ),
                  _c("popup-info-tip", {
                    attrs: { "show-info-tip": _vm.isSurveyInfotip },
                    on: {
                      close: function ($event) {
                        _vm.isSurveyInfotip = false
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.surveys.length
                ? _c("div", { staticClass: "gathering-list" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.surveys, function (item) {
                        return _c("li", { key: item.liveInfoId }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.linkSurvey(
                                    item.contentType,
                                    item.liveInfoId
                                  )
                                },
                              },
                            },
                            [
                              _c("p", [
                                _c("span", [
                                  _vm._v(
                                    "[" + _vm._s(item.survey.summary) + "]"
                                  ),
                                ]),
                                _vm._v(_vm._s(item.title)),
                              ]),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "gathering-else" },
          [
            _vm.topBanner.length > 0
              ? _c(
                  "swiper",
                  {
                    ref: "topBanner",
                    staticClass: "swiper trendBanner",
                    attrs: { options: _vm.swiperOption },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.clickTopBanner($event)
                      },
                    },
                  },
                  _vm._l(_vm.topBanner, function (item) {
                    return _c("swiper-slide", { key: item.id }, [
                      _c("a", [
                        _c("img", {
                          attrs: {
                            src: item.filePath,
                            width: "242",
                            height: "300",
                            loading: "lazy",
                          },
                        }),
                      ]),
                    ])
                  }),
                  1
                )
              : _vm._e(),
            _vm.bottomBanner.length > 0
              ? _c(
                  "swiper",
                  {
                    ref: "bottomBanner",
                    staticClass: "swiper noticeBanner",
                    attrs: { options: _vm.swiperOption },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.clickBottomBanner($event)
                      },
                    },
                  },
                  _vm._l(_vm.bottomBanner, function (item) {
                    return _c("swiper-slide", { key: item.id }, [
                      _c("a", [
                        _c("img", {
                          attrs: {
                            src: item.filePath,
                            width: "242",
                            height: "250",
                            loading: "lazy",
                          },
                        }),
                      ]),
                    ])
                  }),
                  1
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn-go",
                attrs: { id: "btnGo" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.callDrInfo(null)
                  },
                },
              },
              [_vm._v(" 닥터인포 바로가기 ")]
            ),
          ],
          1
        ),
      ]),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-bar" }, [
      _c("h1", [_vm._v("모아보기 약품정보+설문조사+정보")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }